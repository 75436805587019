@import url('https://fonts.googleapis.com/css?family=Raleway:400,400i,500,600,600i,700,700i,800,900');
@import 'const';
@import '../../node_modules/nprogress/nprogress.css';
@import 'nprogress';

:root {
  --font-family-sans-serif: 'Raleway', Roboto, 'Helvetica Neue', Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.ff-extra-bold,
.example-color,
.navbar-brand,
.title,
.tooltip .order-number {
  font-weight: 800;
}
.example-price {
  position: relative !important;
}
.ff-semi-bold,
.navbar-nav a,
.semi-bold-title {
  font-weight: 600;
}
.ff-bold,
.ff-semi-bold b,
.option-value,
.green-circle,
.title b,
.pageTitle,
.semi-bold-title b,
.price-inner {
  font-weight: 900;
}
.ff-medium {
  font-weight: 500;
}
.fs-extra-lg {
  font-size: 45px;
}
.fs-lg {
  font-size: 34px;
}
.fs-semi-lg {
  font-size: 16px;
}
.fs-extra-md {
  font-size: 20px;
}
@media (min-width: $tablet_and_mobile_min_width) {
  .jumbotron {
    padding: 2.3rem 2rem;
  }
}
.single-product .fs-length-1 {
  /* font-size: 70px;
  height: 90px; */
}
.fs-length-2 {
  font-size: 25px;
  padding-top: 5px;
}
.single-product .fs-length-2 {
  /* font-size: 50px;
  height: 75px; */
}
.fs-length-3 {
  font-size: 20px;
  padding-top: 8px;
}
.single-product .fs-length-3 {
  /* font-size: 40px;
  height: 65px; */
}
.fs-length-5,
.fs-length-4 {
  font-size: 15px;
  padding-top: 13px;
}
.single-product .fs-length-4 {
  /* font-size: 30px;
  height: 45px; */
}
.single-product .fs-length-5 {
  /* font-size: 25px;
  height: 55px; */
}
.fs-length-6 {
  font-size: 13px;
  padding-top: 14px;
}
.single-product .fs-length-6 {
  /* font-size: 20px;
  height: 55px; */
}
.always-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
/* Tools */

body {
  overflow-x: hidden;
  font-family: 'Raleway', Roboto, 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  scroll-behavior: smooth;
}

h1 {
  font-size: 1.9rem;
}

.hidden {
  display: none;
}
a {
  color: #000000;
  text-decoration: underline;
}
/* a:hover {
  text-decoration: none;
} */
.dark-color,
.green-circle {
  color: #20041f !important;
}

.color-contrast {
  color: #12dfc0;
}
.color-light {
  color: #fff0fa;
}
.color-pink,
.color-pink.change-color:hover {
  color: #f4b4ce;
}
.color-pink .color-arrow,
.pink-paper {
  background-color: #f4b4ce;
}
.color-green,
a.color-green:hover {
  color: #12dfc0;
}
.color-green .color-arrow {
  background-color: #12dfc0;
}
.color-success {
  color: #35bd51;
}
.color-error-dark {
  color: #d0021b;
}
.color-error {
  color: #ff0000;
}
.dark-bgr {
  background-color: #20041e;
}
.semi-light-bgr {
  background: #f8f8f8;
}
.light-bgr,
.light-bgr.single-details {
  background-color: #fcedf7;
}
.purple-bgr {
  background: #20041e;
}
.purple-light-bgr {
  background: #62295e;
}
.metalic-bgr {
  background: #878c97;
}
.pink-light-brg {
  background: #fad5e5;
}
.green-light-bgr {
  background: #00e0c0;
}
.grey-light-bgr {
  background: #eff3f4;
}
.white-light-bgr {
  background: #ffffff;
}
.reg-color,
.reg-color a {
  color: #f4b4ce;
}
a.color-dark-contrast,
.color-dark-contrast a,
.color-dark-contrast {
  color: #62295e;
}
.blue-bgr {
  background: #009cde;
}

.pink-bgr {
  background: url(../images/pink-bgr.png);
}

.pink-bgr-color {
  background: #ffb5cf;
}

.pink-road-sign {
  background: url(../images/pink-road-sign.png) no-repeat;
  background-size: cover;
}
.road-sign-stright {
  background: url(../images/road-sign-stright.png) no-repeat;
  background-size: cover;
  padding: 5px 35px 5px 45px;
}
.road-sign-stright-long {
  background: url(../images/road-sign-stright-long.png) no-repeat;
  background-size: cover;
}
.cool-bubble {
  background: url(../images/cool-bubble.png) no-repeat;
  background-size: cover;
  display: inline-block;
  padding: 20px 60px 25px 25px;
  position: relative;
  min-width: 244px;
  text-align: left;
}
.cool-bubble span {
  transform: rotate(-3deg);
  -webkit-transform: rotate(-3deg);
  display: inline-block;
}
.cool-checkbox input[type='checkbox'] {
  display: none;
}
.cool-checkbox .checkbox-label {
  display: inline-block;
  margin: 0px 15px;
  vertical-align: top;
}
.has-error .cool-checkbox .checkbox-label {
  color: #ef1c3f;
}
.cool-checkbox input[type='checkbox'] + span {
  background: url(../images/checkbox-bgr.png) no-repeat;
  background-size: cover;
  display: inline-block;
  vertical-align: top;
  height: 35px;
  width: 35px;
  position: relative;
  cursor: pointer;
}
.cool-checkbox input[type='checkbox']:checked + span:before {
  background: url(../images/chekbox-marked.png) no-repeat;
  background-size: cover;
  content: '';
  display: inline-block;
  position: absolute;
  width: 28px;
  height: 25px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.has-error .cool-checkbox input[type='checkbox'] + span {
  background: url(../images/checkbox-error-bgr.png?) no-repeat;
  background-size: 100%;
}
.cool-form .input-text {
  background: url(../images/input-bgr.png) no-repeat;
  background-size: cover;
  outline: none;
  border: none;
  padding: 15px 25px 15px 25px;
  width: 100%;
  margin-left: -5px;
  font-weight: bold;
  font-size: 25px;
}
.cool-form .input-text.has-error {
  background: url(../images/input-error-bgr.png) no-repeat;
  background-size: 100% 70px;
  color: #ef1c3f;
}

.hedgieForm {
  margin-bottom: 100px;
}

.error {
  color: #ef1c3f;
}

.form-line {
  width: 100%;
}
.input-text-holder label {
  display: inline-block;
  width: 100%;
}

.overflow-hidden {
  overflow: hidden;
  max-height: 400px;
  height: 400px;
}
.overflow-hidden-reg {
  overflow: hidden;
}
.buble-on-bgr {
  position: absolute;
  bottom: 250px;
  right: 500px;
}
.bgr-bottom-images {
  margin-top: -40px;
}
.hedige-bubble {
  margin-top: 200px;
}
.hedige-on-bgr-holder {
  margin: 0 -70px 0 0;
}
.road-sticks {
  background: url(../images/road-sticks.png) no-repeat;
  display: inline-block;
  height: 155px;
  width: 100px;
  position: absolute;
}
.grid-road-board,
.example-color {
  background: url(../images/grid-road-board.png) no-repeat;
  background-size: cover;
}
.color-arrow {
  background: url(../images/arrow-transparent.png) no-repeat;
  display: inline-block;
  background-size: cover;
  width: 10px;
  height: 15px;
  display: inline-block;
  float: right;
}
.color-arrow {
  background: url(../images/arrow-transparent.png) no-repeat;
  display: inline-block;
  background-size: cover;
  width: 10px;
  height: 15px;
  display: inline-block;
  float: right;
}
.cool-fieldset-inner-wrap select {
  background-position: 95% 13px;
  background-image: url(../images/arrow-transparent_-down-green.png?hjk);
  background-repeat: no-repeat;
  background-size: 13px 13px;
}
.row-with-bgr {
  position: relative;
}
.primary-row-1 {
  padding-top: 3rem !important;
}
.primary-row-1:before {
  background-color: #baddf6;
  content: '';
  display: inline-block;
  width: 100%;
  height: 129px;
  position: absolute;
  /* 	top:0; */
  /* 	bottom:0; */
  /* 	margin:auto; */
}
.primary-row-2:before {
  background-color: #947192;
  content: '';
  display: inline-block;
  width: 100%;
  margin-top: 21px;
  height: 129px;
  position: absolute;
  /* 	top:0; */
  /* 	bottom:0; */
  /* 	margin:auto; */
}
.primary-row-3:before {
  background-color: #e7b5e3;
  content: '';
  display: inline-block;
  width: 100%;
  height: 129px;
  margin-top: 16px;
  position: absolute;
  /* 	top:0; */
  /* 	bottom:0; */
  /* 	margin:auto; */
}
.primary-row-4:before {
  background-color: #ffe4f0;
  content: '';
  display: inline-block;
  width: 100%;
  height: 129px;
  margin-top: -4px;
  position: absolute;
  /* 	top:0; */
  /* 	bottom:0; */
  /* 	margin:auto; */
}
.primary-row-5:before {
  background-color: #c9ced9;
  content: '';
  display: inline-block;
  width: 100%;
  height: 129px;
  margin-top: 36px;
  position: absolute;
  /* 	top:0; */
  /* 	bottom:0; */
  /* 	margin:auto; */
}
.primary-row-6:before {
  background-color: #c8f2ec;
  content: '';
  display: inline-block;
  width: 100%;
  height: 129px;
  margin-top: 37px;
  position: absolute;
  /* 	top:0; */
  /* 	bottom:0; */
  /* 	margin:auto; */
}
.primary-row-1:before {
  background-position: 0px 5px;
  top: 85px;
}
.primary-row-2:before {
  background-position: 0px -196px;
  top: 15px;
}
.primary-row-3:before {
  background-position: 0px -440px;
  top: 20px;
}
.stick-for-bottom-top {
  background-size: 330px 162px;
  background-position: 0 0;
  left: 64px;
  top: 80px;
}
.stick-for-top-bottom {
  background-size: 330px 162px;
  background-position: -218px 0;
  left: 184px;
  top: 85px;
}
.stick-for-bottom-top-1 {
  background-size: 330px 162px;
  background-position: -100px 0;
  left: 50px;
  top: 80px;
}
.rot-bottom-top {
  -ms-transform: rotate(-13deg); /* IE 9 */
  -webkit-transform: rotate(-13deg); /* Safari 3-8 */
  transform: rotate(-13deg);
}
.rot-top-bottom {
  -ms-transform: rotate(7deg); /* IE 9 */
  -webkit-transform: rotate(7deg); /* Safari 3-8 */
  transform: rotate(7deg);
}
.rot-bottom-top-1 {
  -ms-transform: rotate(-6deg); /* IE 9 */
  -webkit-transform: rotate(-6deg); /* Safari 3-8 */
  transform: rotate(-6deg);
}
.block-title {
  display: inline-block;
  padding: 10px 30px 10px 30px;
}
.title-rot {
  display: inline-block;
  padding: 5px 30px 5px 30px;
  margin: 0 0 0 50px;
  position: relative;
  z-index: 999;
}
.primary-row-1 .title-rot {
  margin-top: 87px;
}
.primary-row-2 .title-rot {
  margin-top: 100px;
}
.primary-row-3 .title-rot {
  margin-top: 100px;
}
.green-circle {
  /*font-size: 25px;*/
  width: 60px;
  height: 60px;
  background: #12dfc0;
  border-radius: 50%;
  -o-border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -ms-border-radius: 50%;
  position: absolute;
  text-align: center;
  vertical-align: middle !important;
}
.booking-confirmation .green-circle {
  /* width: 120px;
  height: 120px; */
}
.price-inner {
  height: 45px;
}
.green-circle {
  display: inline-block;
  padding: 15px;
  position: absolute;
  /* left: -29px;
  top: -6px; */
  z-index: 1;
}

.green-flex-circle {
  display: flex;
  flex-direction: column;
  padding: 15px;
  position: absolute;
  z-index: 1;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: auto;
  height: auto;
}

.green-circle em {
  font-size: 70%;
  vertical-align: top;
  font-style: normal;
}
.road-sign-reg {
  background: url(../images/road-sign-reg.png) no-repeat;
  background-size: cover;
  display: inline-block;
  padding: 7px 30px 8px 20px;
}

.road-sign-stright-left {
  background: url(../images/road-sign-stright-left.png) no-repeat;
  background-size: cover;
  display: inline-block;
  padding: 20px 50px 20px 40px;
}

.buy-product .road-sign-reg {
  padding: 7px 0px 7px 40px;
}
.single-product .road-sign-reg {
  /* padding: 5px 40px 5px 90px;
  margin: 20px 0 0 41px !important; */
  margin: 10px 0 0 45px;
}
.read-more-btn {
  display: inline-block;
  padding: 5px 40px 5px 25px;
  position: relative;
}
.read-more-btn.btn-big {
  display: inline-block;
  padding: 15px 40px 15px 25px;
  position: relative;
}
.read-more-btn.btn-extra-big {
  display: inline-block;
  padding: 15px 50px 15px 40px;
  position: relative;
  cursor: pointer;
  outline: none;
}

.road-sign-stright-left.btn-extra-big {
  display: inline-block;
  padding: 20px 50px 20px 40px;
  position: relative;
}

a.btn {
  text-decoration: none;
}

.btn-pink .color-arrow {
  background-color: #f4b4ce !important;
}
.read-more-btn .color-arrow {
  right: 15px;
}
.section-arrow {
  position: relative;
}

/*border-color: #6980fe transparent transparent transparent;*/

.section-arrow:after {
  /* content: "";
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 40px 0 40px;
  left: 0;
  right: 0;
  bottom: -40px;
  margin: auto; */
}
.dark-bgr.section-arrow:after {
  border-color: #20041e transparent transparent transparent;
}
.pink-bgr.section-arrow:after {
  border-color: #ffbbd7 transparent transparent transparent;
}
.simple-link,
.simple-link:hover {
  position: relative;
  display: inline-block;
  padding: 0 30px 0 0;
  max-width: 230px;
}
.simple-link .color-arrow {
  bottom: 0;
  top: 50%;
  right: 20px;
}
.soc-icon {
  background: url(../images/footer-soc-icons.png?sd) no-repeat;
  display: inline-block;
  vertical-align: top;
  width: 46px;
  height: 46px;
}
.tw-item {
  background-position: -3px 0;
}
.fb-item {
  background-position: -61px 0;
}
.ins-item {
  background-position: -118px 0;
}
.help-hint,
.error-hint {
  display: block;
  width: 100%;
  text-align: center;
  /* margin-top: 50px; */
  position: relative;
}
.has-error .help-hint,
.error-hint {
  display: none;
}
.has-error .error-hint {
  display: block;
}
.pos-rel {
  position: relative;
}
.cool-info .price-label {
  margin: 19px 0 0 50px;
  padding: 13px 40px 10px 40px;
}
.chrome {
  background: url(../images/chrome.png) no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  width: 80px;
  height: 80px;
  position: absolute;
}
/* tooltip*/
.title-with-tooltip {
  position: relative;
  display: inline-block;
}
.c-tooltip {
  background: #12dfc0;
  border-radius: 3px;
  -o-border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -ms-border-radius: 3px;
  color: #20041f;
  position: absolute;
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 0px 5px;
}
.c-tooltip:hover {
  cursor: pointer;
}
.pink-road-sign .c-tooltip {
  right: -15px;
  top: -10px;
}
.disabled-option {
  opacity: 0.5;
}
.disabled-option .type-button {
  color: #f8bad5;
}

.pink-sky {
  background: #f4b4cd url(../images/pink-sky.png) no-repeat;
  /*background-position: center center;*/
  background-size: cover;
}
.img-holder {
  position: relative;
}
.team-photo {
  max-width: 100%;
  width: 100%;
}
.cool-rotate {
  position: absolute;
  max-width: 550px;
  right: -110px;
  bottom: -100px;
}

.sign-up-wrap {
  overflow: hidden;
}
.ok-bubble {
  background-size: 150px;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.ok-bubble-blue {
  background-image: url(../images/bubble-ok.png);
}
.ok-bubble-purple {
  background-image: url(../images/bubble-ok-purple.png);
}
.ok-bubble-green {
  background-image: url(../images/bubble-ok-green.png);
}
.error-bubble {
  background: url(../images/bubble-error.png) no-repeat;
  background-size: 150px;
  background-position: center bottom;
}
.status-bubble {
  margin-top: 100px;
  padding: 50px 50px 26px 35px;
  position: absolute;
  /* max-width: 550px; */
  right: 390px;
  bottom: 290px;
}
.status-bubble span {
  transform: rotate(16deg);
  -webkit-transform: rotate(16deg);
  -ms-transform: rotate(16deg);
  -mox-transform: rotate(16deg);
  display: inline-block;
  padding: 0 0 0 0;
  width: 100%;
  text-align: center;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 20px;
  left: 0;
}
.error-bubble.status-bubble span {
  transform: rotate(20deg);
  -webkit-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  -moz-transform: rotate(20deg);
}

.hedige-appear-from-right {
  position: absolute;
  top: 550px;
  right: -150px;
  width: 660px;
}

.error-bubble.status-bubble {
  padding: 35px 60px 33px 40px;
}
/*space*/
.text-center {
  text-align: center;
}
.title {
  margin: 0 0 0 0;
}

.sub-heading-center {
  max-width: 690px;
  margin: auto;
}

.large-sub-heading {
  font-size: 3em !important;
}
/* header */
/* header */

header .navbar-brand {
  color: #ffffff;
  text-decoration: none;
}

header .navbar-brand a {
  color: #ffffff;
  text-decoration: none;
}

header .nav-header {
  background: none;
}

header .navbar-nav {
  padding-left: 40px;
}

.desktop-sidebar {
  position: fixed;
  right: -280px;
  top: 0px;
  bottom: 0px;
  width: 280px;
  z-index: 10001;
  background: #41163e;
  transition: 0.2s;
}

.desktop-sidebar.opened {
  right: 0px;
}

.desktop-sidebar .sidebar-content {
  padding: 40px;
}

.desktop-sidebar .sidebar-content .navEmail {
  color: #12dfc0;
  margin-bottom: 15px;
}

.desktop-sidebar .sidebar-content .desktopUserMenu {
  padding: 15px 0px;
}

.desktop-sidebar .sidebar-content .desktopUserMenu .nav-link {
  padding-left: 0px;
  padding-right: 0px;
  color: #e0e0e0;
}

.desktop-sidebar .sidebar-content .desktopUserMenu .nav-link:hover {
  color: #ffffff;
}

@media (min-width: $tablet_and_mobile_min_width) {
  header .nav-header {
    /*background: url(../images/top_nav.svg) no-repeat center;*/
    background-size: cover;
    background-color: #380934;
  }

  .fixed-header header .nav-header {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 12;
  }

  header .navbar-brand {
    position: absolute;
    color: #62295e !important;
    margin-left: 4rem;
  }
  header .navbar-nav li:last-child:after {
    display: none;
  }
  header .navbar-nav {
    padding-left: 0;
  }
}

@media (max-width: $tablet_and_mobile_max_width) {
  header .nav-header {
    background-color: #380934;
  }
  header .navbar-collapse {
    margin-left: -15px;
    margin-bottom: -15px;
    margin-right: -15px;
    /*     padding: 40px 0; */
  }
}

header .nav-link {
  text-decoration: none;
  font-size: 1rem;
}

header .navbar-nav li {
  position: relative;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

header .navbar-nav li:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 20px;
}

header .navbar-nav li.sign-in {
  border-top: none;
}

header .navbar-nav .mobileUserMenu .navEmail {
  padding: 15px 0;
  color: #12dfc0;
  text-overflow: ellipsis;
  overflow: hidden;
}

header .navbar-nav .mobileUserMenu {
  padding: 15px 0;
  max-width: calc(100vw - 80px);
}

header .navbar-nav .mobileUserMenu li {
  border-top: none;
  border-bottom: none;
  color: #ffffff;
}

header .navbar-dark .dark-bgr .mobileUserMenu .nav-link {
  color: #ffffff;
}

header .navbar-nav.mobileUserMenuNav {
  background-color: rgba(98, 41, 94, 0.5);
}

/* header .navbar-nav li:after {
  content: "";
  width: 2px;
  height: 2px;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -2px;
  margin: auto;
  background: #62295e;
  border-radius: 50%;
  -o-border-radius: 50%;
  -moz-border-radius: 50%;
  -webmit-border-radius: 50%;
  -ms-border-radius: 50%;
} */

header .navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

@media (min-width: $tablet_and_mobile_min_width) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  header .navbar-nav li {
    border-top: none;
  }

  header .navbar-nav li:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  header .logged-in:after {
    content: '';
    width: 100px;
    height: 100%;
    background: url(../images/header-hedgie.png) no-repeat;
    background-position: bottom right;
    background-size: contain;
    position: absolute;
    right: 0;
    display: none;
  }

  header .navbar {
    /* padding-top: 30px;
    padding-bottom: 30px; */
    height: 58px;
  }

  .navbar-light .navbar-nav .nav-link.btn {
    color: #ffffff;
  }

  header .user-sub-menu {
    background-color: #80e0df;
  }
}

.logged-in .navbar-nav > li:last-child > a {
}

header .logged-in .navbar-nav {
  /* padding-right: 130px; */
}
/* header .logged-in .navbar-nav:after {
  content: "";
  width: 100px;
  height: 100%;
  background: url(../images/orange-hedgie.png) no-repeat;
  background-position: top left;
  position: absolute;
  right: 0;
} */

.navbar-nav > li > a {
  /* padding-top: 15px;
  padding-bottom: 15px; */
}
.has-submenu {
  position: relative;
}

@media (min-width: $tablet_and_mobile_min_width) {
  header .navbar-nav li:last-child {
    /* margin-right: -30px; */
  }
}

.has-submenu > a {
  position: relative;
}
.has-submenu > a:after {
  content: '';
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #62295e transparent transparent transparent;
  line-height: 0;
  _border-color: #62295e #000000 #000000 #000000;
  _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
}
.has-submenu > a > .menu-icon {
  width: 30px;
  display: inline-block;
}

/* .has-submenu > a > .menu-icon {
  content: "";
  position: absolute;
  right: -7px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #62295e transparent transparent transparent;
  line-height: 0;
  _border-color: #62295e #000000 #000000 #000000;
  _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
} */
.sub-menu {
  background-color: #cccccc;
  /* background-color: #20041e; */
  list-style-type: none;
  padding: 0;
  visibility: hidden; /* hides sub-menu */
  opacity: 0;
  position: absolute;
  /* top: 48px; */
  left: 10px;
  width: 100%;
  transform: translateY(-2em);
  z-index: 1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
}

.has-submenu:hover > .sub-menu {
  visibility: visible; /* shows sub-menu */
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

/** flexed list **/
.flexed-list > div {
  min-width: 40%;
}

@media (min-width: 500px) {
  .flexed-list > div {
    min-width: auto;
  }
}

/**Home page**/

.welcome-section {
  padding: 50px 0 30px 0;
  background: url(../images/home-backround.png) no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: scroll;
}

.welcome-section .jumbotron {
  color: #62295d;
}

@media (min-width: $tablet_and_mobile_min_width) {
  .welcome-section {
    padding-bottom: 200px;
  }
}
.row-section .jumbotron {
  background-color: rgba(0, 0, 0, 0);
}

.thank-you-section {
  padding: 50px 0 150px 0;
  background-position: center center;
  background-size: cover;
  background-attachment: scroll;
}

.checked-list {
  list-style: none;
}

.checked-list li:before {
  font-family: 'FontAwesome';
  content: '\F058';
  margin: 0px 15px 0 -15px;
  color: #cccccc;
  font-size: 1.2rem;
}

.checked-list li {
  padding-bottom: 1rem;
}

@media (min-width: $tablet_and_mobile_min_width) {
  .checked-list li:before {
    color: #62295d;
    font-size: 1.2rem;
    margin-left: 10px;
  }
}
/* steps */
.step-item p,
.step-item {
  color: #d0becc;
}
.current-step p,
.current-step {
  color: #3c2139;
}
.step-number {
  display: inline-block;
  padding: 7px 15px;
  border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  position: relative;
  background: #fff;
  z-index: 9;
}

.step-item .step-number {
  border: 2px solid #d0becc;
}
.current-step .step-number {
  border: 2px solid #3c2139;
}
.step-odd .step-number-holder,
.step-even .step-number-holder {
  position: relative;
  z-index: 999;
  overflow: hidden;
}
.step-odd .step-number-holder:after,
.step-even .step-number-holder:after {
  position: absolute;
  content: '';
  width: 50%;
  height: 2px;
  background: #cabec6;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.step-even .step-number-holder:after {
  left: 0;
  right: 100%;
}
.step-title {
  display: inline-block;
  width: 100%;
}
/* work-space */
.work-space {
  background-position: left bottom;
  padding: 50px 65px;
  background-size: 100% 370px;
  background-repeat: no-repeat;
}
.color-picker-holder {
  width: 300px;
  height: 300px;
  /* height: 215px; */
  background-size: contain;
  position: relative;
  /* margin-top: 60px; */
}

.result-holder {
  position: relative;
  text-align: center;
  margin-top: 25px;
}

@media (min-width: $tablet_and_mobile_min_width) {
  .result-holder {
    margin-top: -50px;
  }
}

.actual-result {
  max-width: 400px;
  position: relative;
  display: inline-block;
}

@media (max-width: 1096px) {
  .actual-result {
    max-width: 100%;
  }
}

.result-color-code {
  position: absolute;
  bottom: 90px;
  left: 80px;
  z-index: 999;
}

.color-code,
.result-color-code {
  color: #fff;
}

.result-holder:before {
  background: url(../images/hedgie-nest.png) no-repeat;
  content: ' ';
  display: inline-block;
  width: 120%;
  height: 50px;
  background-size: cover;
  position: absolute;
  bottom: 35px;
  left: -23px;
}
.result-holder:after {
  background: url(../images/hedgie-front-stage.png) no-repeat;
  content: ' ';
  display: inline-block;
  width: 90%;
  height: 190px;
  background-size: cover;
  position: absolute;
  bottom: -49px;
  left: 0px;
}
.option-form {
  text-align: center;
}
.single-product .option-form {
  text-align: left;
  padding-left: 80px;
}
.add-new-wrap {
  text-align: left;
  padding-left: 60px;
}
.add-new-wrap .title {
  display: inline-block;
}
.work-space .price-label,
.landing-picker .price-label {
  margin: 10px 0 0 45px;
}
.price-label label {
  margin-bottom: 0;
}
.work-space .price-label a label,
.landing-picker .price-label a label {
  cursor: pointer;
}
.result-options {
  /* margin-top: -13px; */
  margin-top: -80px;
  //height: 449px;
  padding-left: 50px;
}
.result-option-item,
.option-value {
  display: inline-block;
  vertical-align: top;
  width: 41px; /*55*/
}
.option-value {
  width: 100%;
  text-align: center;
  font-size: 13px;
}
/*
.opt-item{
	background: url(../images/power-optioins.png) no-repeat;
	background-size: 230px 52px;
	width: 100%;
	height: 50px;
	cursor:pointer;
	margin-bottom: 0;
}
.opt-item-type{
	display:none
}

.opt-item-1{
	background-position: -4px 0px;
}
.opt-item-2{
	background-position: -61px 0px;
}
.opt-item-3{
	background-position: -116px 0px;
}
.opt-item-4{
	background-position: -173px 0px;
}*/
/*  posibilities */
.premium-link {
  display: inline-block;
  height: 70px;
  padding-top: 10px;
  padding: 10px 17px 0px 45px;
}

/* grid */
.example-details {
  text-align: center;
  margin-top: -25px;
  margin-left: 30px;
}
.example-color {
  display: inline-block;
  padding: 10px 30px 10px 15px;
  position: relative;
  min-width: 130px;
}
.color-arrow,
.title--arrow:after {
  background: url(../images/arrow-transparent.png) no-repeat;
  display: inline-block;
  background-size: cover;
  width: 15px;
  height: 15px;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 5px;
  margin: auto;
}
.title-arrow:after {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 7px;
  border-color: transparent transparent transparent #6980fe;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 5px;
  margin: auto;
}
.title-arrow {
  position: relative;
}
.fs-lg.title-arrow,
.fs-extra-lg.title-arrow {
  padding-right: 40px !important;
}
.fs-lg.title-arrow:after,
.fs-extra-lg.title-arrow:after {
  content: '';
  right: 15px;
  background-size: 25px 25px;
  border-width: 10px 0 10px 12px;
  /*width: 20px;*/
  /*height: 20px;*/
  top: -5px;
}
.title-arrow:after {
  content: '';
  right: -18px;
  /*width: 20px;*/
  /*height: 20px;*/
}
.change-color.title-arrow:after {
  right: -18px;
  bottom: 10px;
  top: auto;
}
.fs-semi-lg.title-arrow:after {
  border-width: 7px 0 7px 8px;
}
.arrow-bottom.title-arrow:after {
  right: 18px;
  bottom: 10px;
  top: auto;
}
.fs-extra-md.title-arrow:after {
  border-width: 6px 0 6px 7px;
}
.color-light.title-arrow:after {
  /*background-color:#fff;*/
  border-color: transparent transparent transparent #fff;
}
.color-contrast.title-arrow:after {
  /*background-color:#12dfc0;*/
  border-color: transparent transparent transparent #12dfc0;
}
.dark-color.title-arrow:after {
  /*background-color:#12dfc0;*/
  border-color: transparent transparent transparent #20041f;
}
.color-pink.title-arrow:after {
  /*background-color:#12dfc0;*/
  border-color: transparent transparent transparent #f4b4ce;
}
.title-rot.title-arrow:after {
  right: 10px;
}
.read-more-btn.title-arrow:after {
  right: 15px;
}
.form-submit-btn.title-arrow:after {
  right: 30px;
}
.form-submit-btn {
  margin-left: -5px;
}
.price-info {
  color: #fff;
  display: inline-block;
  padding: 5px 5px 5px 50px;
  background: #f8bad5;
  font-size: 10px;
  margin: 10px 9px 0 13px;
}

.flex-vcenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
}

/* footer */

.footer-nav {
  float: right;
  text-align: left;
  list-style-type: none;
  padding: 0 30px 0 0;
  position: relative;
  margin-bottom: 0;
  font-size: 1.1rem;
}

.footer-nav a {
  text-decoration: none;
  color: #b163ac;
}

.footer-nav a:hover {
  color: #13dfc0;
}

.footer-nav:after {
  content: '';
  width: 2px;
  height: 95%;
  background: #62295e;
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 0;
}

.soc-icons a {
  padding-left: 5px;
}

.soc-icons {
  width: 100%;
}
@media (max-width: $tablet_and_mobile_max_width) {
  .soc-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .social-column {
    justify-content: center;
  }
  .soc-icons a {
    margin: 6px;
  }
}

.copyrightFooter p {
  margin-bottom: 0;
  margin-top: 50px;
  font-size: 0.7rem;
  color: #b064ac;
}

/* single-product */
.single-product-container {
  padding: 0 160px;
}
.single-product {
  background: #fff;
  margin-top: 80px;
}
.single-product .result-price {
  margin-left: -45px;
}
.single-result {
  position: absolute;
  right: -30px;
  max-width: 435px;
  top: 90px;
}
.getway-type {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding-right: 5px;
}
.price-value {
  display: block;
  vertical-align: top;
  /*width: 100%;*/
  text-align: center;
}
.type-button {
  display: inline-block;
  padding: 5px 25px;
  border-radius: 25px;
  -o-border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  -ms-border-radius: 25px;
}
.gift-code-wrap,
.gift-code-inner-wrap,
.cool-fieldset,
.cool-fieldset-inner-wrap {
  display: inline-block;
}
.gift-code-wrap .type-button,
.cool-fieldset .input-label {
  float: left;
  border-radius: 25px 0 0 25px;
  -o-border-radius: 25px 0 0 25px;
  -moz-border-radius: 25px 0 0 25px;
  -webkit-border-radius: 25px 0 0 25px;
  -ms-border-radius: 25px 0 0 25px;
}
.cool-fieldset select.input-label {
  outline: none;
  -webkit-appearance: none;
  -o-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 4px 35px 4px 25px;
}
#gift_code,
.cool-fieldset .input-text {
  float: left;
  margin-left: -5px;
  border-radius: 0 25px 25px 0;
  -o-border-radius: 0 25px 25px 0;
  -moz-border-radius: 0 25px 25px 0;
  -webkit-border-radius: 0 25px 25px 0;
  -ms-border-radius: 0 25px 25px 0;
  border: none;
  padding: 8px;
  outline: none;
  text-align: center;
}
.has-error .type-button,
.has-error #gift_code,
.has-error.cool-fieldset .input-text,
.has-error.cool-fieldset .input-label {
  border: 2px solid red;
}
.has-error .type-button {
  border-right: none;
}
.type-button-1 {
  padding: 5px 35px;
}
.type-button-2,
.type-button-3 {
  padding: 5px 29px;
}
.has-error #gift_code {
  border-left: none;
  color: #ff0000;
}
.buy-product {
  position: absolute;
  bottom: 80px;
  right: -184px;
}
.product-title {
  padding-left: 50px;
}
.change-color,
.change-color:hover {
  position: absolute;
  top: 30px;
  width: 80px;
  right: 60px;
  line-height: 20px;
  padding-left: 30px;
}
/* buy-gift */
.buy-gift {
  position: relative;
  padding: 0;
  overflow: hidden;
  height: 400px;
}
.buy-gift:before {
  content: '';
  width: 100%;
  height: 40px;
  background: #fcedf7;
  display: inline-block;
  position: absolute;
  top: 0;
  z-index: 99;
}
.gift-result {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
.buy-gift .buy-product {
  position: absolute;
  bottom: 0;
  right: 100px;
  z-index: 9999999999999999999;
  top: 35px;
}

/* sign up form*/

.primary-row-4:before {
  background-position: 0px 5px;
  top: 40px;
}
.primary-row-5:before {
  background-position: 0px -191px;
}
.primary-row-6:before {
  background-position: 0px -427px;
}
.stick-for-bottom-top-4 {
  background-size: 330px 162px;
  background-position: 0 0;
  left: 64px;
  top: 100px;
}
.stick-for-top-bottom-5 {
  background-size: 330px 162px;
  background-position: -218px 0;
  left: 184px;
  top: 85px;
}
.stick-for-bottom-top-1-6 {
  background-size: 330px 162px;
  background-position: -100px 0;
  left: 50px;
  top: 80px;
}
.rot-bottom-top-4 {
  -ms-transform: rotate(-13deg); /* IE 9 */
  -webkit-transform: rotate(-13deg); /* Safari 3-8 */
  transform: rotate(-13deg);
}
.rot-top-bottom-5 {
  -ms-transform: rotate(7deg); /* IE 9 */
  -webkit-transform: rotate(7deg); /* Safari 3-8 */
  transform: rotate(7deg);
}
.rot-bottom-top-1-6 {
  -ms-transform: rotate(-6deg); /* IE 9 */
  -webkit-transform: rotate(-6deg); /* Safari 3-8 */
  transform: rotate(-6deg);
}
.primary-row-4 .title-rot {
  margin-top: 100px;
}
.primary-row-5 .title-rot {
  margin-top: 100px;
}
.primary-row-6 .title-rot {
  margin-top: 100px;
}
.member-options-wrap {
  position: absolute;
  bottom: -10px;
  right: 0;
  width: 250px;
  text-align: right;
}
.info-options {
  display: inline-block;
  vertical-align: top;
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.info-options.twitter {
  background: #6e2860 url(../images/twitter.png) no-repeat;
  background-size: 75%;
  background-position: center;
}

.info-options.linkedin {
  background: #6e2860 url(../images/linkedin.png) no-repeat;
  background-size: cover;
}

.info-options.medium {
  background: #6e2860 url(../images/medium.png) no-repeat;
  background-size: cover;
}

.height-placeholder {
  height: 100px;
}

.cursor-pointer {
  cursor: pointer;
}

.power-row {
  display: flex;
  justify-content: center;
}

.modal-content {
  /*background: none !important;*/
  border: none;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%);
  /*max-width: 400px;*/
}
.dark-modal-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  .modal-content {
    background: none !important;
  }
}
.road-sign-contrast {
  background: url(../images/contrast-road-sign.png) no-repeat;
  background-size: cover;
  border: 0px;
}
.read-more-btn.title-arrow.extra-btn-big:after {
  right: 50px;
}
.read-more-btn.extra-btn-big {
  display: inline-block;
  padding: 10px 70px 10px 50px;
  position: relative;
}
.modal-dialog {
  height: 100%;
}
.btn-modal {
  margin: auto;
}
.top-modal-center {
  position: absolute;
  left: 50%;
}

/* instal stesp*/
.install-step-holder {
  display: inline-block;
}
.order-number {
  background: url(../images/order-number-bgr.png) no-repeat;
  height: 100px;
  width: 115px;
  background-size: cover;
  position: relative;
  background-position: center;
  display: inline-block;
}
.order-number span {
  position: absolute;
  display: inline-block;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 65px;
  line-height: 30px;
  width: 75px;
  height: 50px;
  color: #fff;
  font-weight: bold;
  /* background:gold; */
}
.install-step-item .title-row {
  height: 100px;
  vertical-align: top;
  position: relative;
  display: inline-block;
  width: 100%;
}
.install-step-item .title-row h2 {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 75px;
  text-align: left;
}

.la {
  text-align: left;
}

.power-options-booking {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.power-item {
  flex: 0 1 0;
  margin: 10px;
}

.premium-price .green-circle {
  left: -50px;
  top: -6px;
}

/* .premium-price .green-circle.reserved {
  left: -20px;
  top: -6px;
} */

.modal-backdrop.show {
  opacity: 0.25;
}

/* About - Page */

.huge-title {
  font-size: 80px;
}
/* .color-dark-contrast{
	color: #053d40;
} */
.color-ex-dark {
  color: #022426;
}
.arrow-down-dark.color-dark-contrast:before {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #053d40 transparent transparent transparent;
  right: 10px;
  position: absolute;
  top: 32px;
}

/*presale-wrap*/

/* .presale-wrap{
	background: url(../images/hedgie-presale-main-bgr.jpg) no-repeat;
	background-size: cover;
	background-position:top center;
} */

.color-picker-tool-wrap {
  background: url(../images/dark-ground-stick.png) no-repeat;
  background-position: 130px 100%;
  padding-bottom: 150px;
  margin-left: -50px;
}
.presale-wrap-inner,
.color-picker-wrap {
  padding-top: 350px;
}
.product-info {
  padding-top: 180px;
}
.simple-road-sign-right .simple-road-sign-content {
  position: absolute;
  top: 70px;
  left: 50px;
}
.simple-road-sign-left .simple-road-sign-content {
  position: absolute;
  top: 46px;
  right: 100px;
}
.simple-road-sign-right .simple-road-map {
  margin-left: -50px;
}
.example-result {
  position: absolute;
  max-width: 550px;
  right: -110px;
  bottom: -270px;
}
.simple-road-sign-left {
  /*margin-right: -50px;*/
}
.simple-road-sign-content {
  width: 65%;
}
.simple-road-sign-right,
.simple-road-sign-left {
  max-width: 685px;
}
.leavs-arround-road-sign {
  position: absolute;
  width: 350px;
  bottom: 0;
  right: -150px;
}
.leavs-arround-road-sign-left {
  position: absolute;
  width: 350px;
  bottom: 70px;
  left: -80px;
}
/* complete-list-holder */
.complete-list-holder {
  padding-top: 70px;
}
.contrast-bubble-left {
  background: url(../images/contrast-bubble-left.png) no-repeat;
  padding: 25px 80px 10px 80px;
  display: inline-block;
}
.complete-list-label {
  position: relative;
}
/*make-your-hedgie*/
.make-your-hedgie {
  padding: 30px 0 300px 0;
}

.booking-error {
  padding-top: 20px;
}

.power-label {
  display: block;
  font-size: 12px;
}

.performs-label {
  display: block;
  text-align: center;
  padding-top: 3px;
  font-size: 9px;
}

.power-container {
  padding: 2px 5px;
  min-width: 70px;
}

/* Stripe.js Form */

.StripeElement {
  margin: auto;
  background-color: white;
  height: 40px;
  width: 100%;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid #ced4db;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/* Purchase */

.purchase-container {
  background-color: #eff3f4;
  margin: 20px -15px 0 -15px;
  padding: 0;
}

.tabbed-container .nav-tabs .nav-item .nav-link {
  cursor: pointer;
}

/* Purchase override BS */
.tabbed-container .nav-tabs .nav-item.show .nav-link,
.tabbed-container .nav-tabs .nav-link.active {
  color: #495057;
  border-color: transparent;
  margin-bottom: 20px;
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-bottom-color: #12dfbe;
  background-color: transparent;
}
.nav-tabs .nav-link {
  border-top: none;
  border-right: none;
  border-left: none;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
  border-bottom-style: none;
  border-width: none;
  opacity: 0.8;
}

.tabbed-container .nav-tabs {
  border-bottom: 0px solid #dee2e6;
}

.note {
  font-size: 0.64rem;
}

.button-link {
  color: #ffffff;
}

.button-link:hover {
  color: #000000;
}

.modal-content {
  /*background: none !important;*/
  /*border: none;*/
  /*position: absolute;*/
  /*left: 50%;*/
  /*top: 50%;*/
  /*transform: translate(-50%, -50%);*/
  /*max-width: 400px;*/
}

.large-modal,
.metamask-modal {
  max-width: 100%;
  width: auto;
}

.large-modal .modal-content {
  background-color: #ffffff !important;
  width: 900px;
  max-width: 100%;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%);
  .modal-header {
    position: relative;
    border-bottom: none;
    padding: 0 1rem;
    .close {
      position: absolute;
      z-index: 90;
      top: 5px;
      right: 12px;
    }
  }
  .modal-title {
    display: none;
  }
}

.metamask-modal .modal-content {
  background-color: #ffffff !important;
  width: 900px;
  max-width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.metamask-modal .modal-title {
  text-align: center;
  width: 100%;
}

.metamask-modal .modal-content img {
}

/** Settings screen **/

@media (max-width: $tablet_and_mobile_max_width) {
  .side-hedgie-secret {
    display: none;
  }
}

/* Gift Screen */

@media (max-width: $tablet_and_mobile_max_width) {
  .side-hedgie-gift {
    display: none;
  }
  .side-hedgie-signup {
    display: none;
  }
}

/* Premium Screen */
.premimum-tabs-1 {
  background-color: #eff3f4;
}

.premimum-tabs-1 .nav-link {
  cursor: pointer;
}

.premimum-tabs-1 .nav-link.active {
  border-color: transparent;
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-bottom-color: #12dfbe;
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
}

.tier-block h3 {
  font-weight: bold;
}

.large-nav .nav-link {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 0;
  padding-right: 0;
  margin-right: 25px;
  font-size: 1.4rem;
  font-weight: 600;
}

@media (min-width: $tablet_and_mobile_min_width) {
  .large-nav .nav-link {
    margin-right: 50px;
  }
}

.tier-unique-item-img {
  max-width: 125px;
  max-height: 100px;
}

/** About Screen **/
.huge-title {
  font-size: 80px;
}
.journey-description {
  color: #ffffff;
}
.full-width-height {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.world-block-container {
  height: 50%;
}

.mobile-full-world {
  /* max-height: 350px;
  height: 100%;
  margin-bottom: 30px;
  position: relative; */
  height: 100vh;
  position: absolute;
  margin-left: -15px;
  margin-right: -15px;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.mobile-full-world .full-world-block {
}

.world-block,
.full-world-block,
.world-block-mobile {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.world-block-mobile {
  background-size: cover;
}

.world-block.world-1,
.world-block-mobile.world-1 {
  background-image: url(../images/forest-world-small.png);
}

.world-block.world-2,
.world-block-mobile.world-2 {
  background-image: url(../images/wetlands-world-small.png);
}

.world-block.world-3,
.world-block-mobile.world-3 {
  background-image: url(../images/mountains-world-small.png);
}

.world-block.world-4,
.world-block-mobile.world-4 {
  background-image: url(../images/desert-world-small.png);
}

.full-world-block.full-world-1 {
  background-image: url(../images/forest-world-full.png);
}

.full-world-block.full-world-2 {
  background-image: url(../images/wetlands-world-full.png);
}

.full-world-block.full-world-3 {
  background-image: url(../images/mountains-world-full.png);
}

.full-world-block.full-world-4 {
  background-image: url(../images/desert-world-full.png);
}

.world-block-button {
  width: 30px;
  height: 30px;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-family: 'Rales-SemiBold';
  background-color: #ffffff;
  color: #62295e;
  border-radius: 50%;
  outline: none;
}

.world-block-section {
  position: absolute;
  text-align: center;
  bottom: 0px;
  width: 100%;
  left: 0;
  margin: 0;
}

@media (min-width: $tablet_and_mobile_min_width) {
  .world-block-section {
    right: 0;
    top: 0;
    left: initial;
    bottom: initial;
    margin: 30px 45px;
    text-align: right;
    color: #ffffff;
  }
}

.mobile-full-world .world-full-close-button {
  margin: 0;
}

.button-text {
  background-color: transparent;
  color: #ffffff;
  border: 0;
}

.world-block-section.button-text {
  font-size: 1rem;
}

.world-full-close-button {
  margin-left: 10px;
}

.world-block-button:active {
  outline: none;
}

.world-block-button:hover {
  cursor: pointer;
}

.world-block-button.hideButton {
  display: none;
}

.world-block-button.open-button {
  left: 0;
  bottom: 0;
  position: absolute;
  margin: 30px 45px;
}

.full-world-details {
  color: #ffffff;
}

.full-world-details .world-name {
  font-size: 1.5rem;
}

.full-world-details .world-details {
  margin-top: 0.5rem;
}

.mobile-world {
  /* padding-left: 15px;
  padding-right: 15px; */
  margin-left: -30px;
  margin-right: -30px;
}

.mobile-world > .col-3 {
  margin: 0;
  padding: 0;
}

/** My Hedgies **/

.hedgieListItem {
  max-width: 23%;
}

.hedgieListItem .selected-indicator {
  display: none;
}

.hedgieListItem.selected {
  /*margin-top: -25px;*/
}

.hedgieListItem.selected .selected-indicator {
  display: block;
}

.myhedgies-metamask {
}

.myhedgies-metamask .button-title-text {
  margin-bottom: 5px;
  display: block;
}

.myhedgies-metamask button {
  margin: 0px !important;
}

/** Landing Screen **/

@media (max-width: $tablet_and_mobile_max_width) {
  .landing-picker .result-holder:before,
  .landing-picker .result-holder:after {
    display: none;
  }

  .landing-picker .result-holder:after {
    display: inline-block;
    background-image: url(../images/hedgie-mobile-front.png);
    display: inline-block;
    width: 80%;
    height: 207px;
    background-size: 80%;
    bottom: -70px;
    left: 77px;
  }
}

/** Roadmap **/

.milestone-col {
  position: relative;
  padding-left: 80px;
}

.milestone-col > div {
}

.milestone-col .milestone-marker {
  width: 50px;
  height: 100%;
}

.milestone-container-inner {
  border-left: 1px dotted #62295e;
}

.milestone-col::before {
  content: '';
  position: absolute;
  width: 53px;
  height: 63px;
  left: -26px;
  background-color: transparent;
  top: 0px;
  z-index: 1;
  background-position-y: 0px;
  background-position-x: 0px;
  background-repeat: no-repeat;
  background-size: contain;
}

.milestone-col.locked::before {
  background-image: url(../images/locked.png);
}

.milestone-col.unlocked::before {
  background-image: url(../images/unlocked.png);
}

@media (max-width: $tablet_and_mobile_max_width) {
  .milestone-container-inner {
    margin-left: 25px;
  }

  .milestone-col {
    position: relative;
    padding-left: 50px;
  }
}

.milestone-col .pageSubHeading {
  display: inline-block;
}

.milestone-col .callout {
  padding-bottom: 1rem;
}

@media (max-width: $tablet_and_mobile_max_width) {
  .side-hedgie-milestones {
    display: none;
  }
}

/** The Team **/
.our-team-page .sub-heading-bold {
  padding-bottom: 0 !important;
}

/** Sign Out **/
@media (max-width: $tablet_and_mobile_max_width) {
  .mobile-side-hedgie {
    width: 250px;
  }
}

/** global button hover **/
.btn-secondary:hover {
  background-color: #13dfc0;
  border-color: #13dfc0;
}

/** Exclusive hedgies tab **/
@media (min-width: $tablet_and_mobile_min_width) {
  .exclusive-tab {
    padding: 0 100px;
  }
}

/** Footer icons **/
.fa-fw:hover .fa-circle {
  color: #13dfc0;
}

.linked-heading:hover {
  color: #12dfc0;
}
.hedgie-slider {
  .slider-frame {
    padding: 10px 0 !important;
  }
}
.hedgie-slider .slider-control-bottomcenter {
  display: none;
}

.hedgie-slider .slider-control-centerleft {
  visibility: hidden;
}
.hedgie-slider .slider-control-centerleft button {
  visibility: hidden;
}

.hedgie-slider .slider-control-centerleft button::after {
  position: absolute;
  left: -50%;
  padding: 0 10px;
  visibility: visible !important;
  border-radius: 100px;
  color: #62295e;
  content: '\2039';
  line-height: 30px;
  font-size: 40px;
}

.hedgie-slider .slider-control-centerright button {
  visibility: hidden;
}
.hedgie-slider .slider-control-centerright {
  visibility: hidden;
}

.hedgie-slider .slider-control-centerright button::after {
  position: absolute;
  right: -50%;
  padding: 0 10px;
  visibility: visible !important;
  border-radius: 100px;
  color: #62295e;
  content: '\203A';
  line-height: 30px;
  font-size: 40px;
}

.primary-content {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.body-content {
  flex: 1;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-root {
  position: relative;
  z-index: 99999999999999;
}
.toast-message {
  border-radius: 5px;
  overflow: hidden;
}

svg {
  overflow: auto !important;
  vertical-align: inherit !important;
}

@media screen and (max-width: 480px) {
  .tabbed-container {
    .nav-tabs {
      .nav-item {
        .nav-link {
          font-size: 15px;
          font-weight: 700;
        }
      }
    }
  }
}

.blink_me {
  animation: blinker 1s linear infinite;
  color: #00e0c0;
  font-weight: bold;
  font-size: 20px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

  
.otpInputStyle {
    margin: 0 5px;
    text-align: center;
    line-height: 80px;
    font-size: 50px;
    border: solid 1px #ccc;
    box-shadow: 0 0 5px #ccc inset;
    outline: none;
    width: 20%;
    transition: all .2s ease-in-out;
    border-radius: 3px;
    
    &:focus {
      border-color: purple;
      box-shadow: 0 0 5px purple inset;
    }
    
    &::selection {
      background: transparent;
    }
  }